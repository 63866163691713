import React from 'react'

const HeaderGeneric = props => (
  <header id="header">
    <h1>{props.title || 'Generic'}</h1>

    {props.children || null}
  </header>
)

export default HeaderGeneric
