import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Reach Out</h2>
      <p>I love to chat and connect so please feel free to get in touch.</p>
    </section>

    <section>
      <h2>Nick Campbell</h2>

      <ul className="icons">
        <li>
          <a
            href="https://linkedin.com/in/nicholasjcampbell/"
            className="icon fa-linkedin alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Linked In</span>
          </a>
        </li>

        <li>
          <a
            href="https://twitter.com/ncb000gt"
            className="icon fa-twitter alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Twitter</span>
          </a>
        </li>

        <li>
          <a
            href="https://instagram.com/ncb000gt"
            className="icon fa-instagram alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Instagram</span>
          </a>
        </li>

        <li>
          <a
            href="https://github.com/ncb000gt"
            className="icon fa-github alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>

        <li>
          <a
            href="mailto:nicholas.j.campbell@gmail.com"
            className="icon fa-envelope alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">E-mail</span>
          </a>
        </li>
      </ul>
    </section>

    <p className="copyright">
      &copy; {new Date().getFullYear()}. Original design by:{' '}
      <a href="https://html5up.net" target="_blank" rel="noopener noreferrer">
        HTML5Up
      </a>
      . Original dodified by me.
    </p>
  </footer>
)

export default Footer
